import { types } from 'mobx-state-tree'

export const type = types.model({
  nr: types.optional(types.maybeNull(types.number), null),
  gemeinde: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  flurname: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  radius: types.optional(types.maybeNull(types.number), null),
  hoehe: types.optional(types.maybeNull(types.number), null),
  exposition: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  klima: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  neigung: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenTyp: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenKalkgehalt: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenDurchlaessigkeit: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenHumus: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenNaehrstoffgehalt: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bodenAbtrag: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  wasserhaushalt: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  beschreibung: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  katasterNr: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  status: types.optional(types.maybeNull(types.number), null),
  statusUnklar: types.optional(types.maybeNull(types.boolean), null),
  statusUnklarGrund: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  apberRelevant: types.optional(types.maybeNull(types.boolean), null),
  apberRelevantGrund: types.optional(types.maybeNull(types.number), null),
  bekanntSeit: types.optional(types.maybeNull(types.number), null),
  eigentuemer: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  kontakt: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  nutzungszone: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bewirtschafter: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bewirtschaftung: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  ekfrequenz: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  ekfrequenzAbweichend: types.optional(types.maybeNull(types.boolean), null),
  ekfrequenzStartjahr: types.optional(types.maybeNull(types.number), null),
  ekfKontrolleur: types.optional(types.maybeNull(types.string), null),
  bemerkungen: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
})

export const initial = {
  nr: null,
  gemeinde: null,
  flurname: null,
  radius: null,
  hoehe: null,
  exposition: null,
  klima: null,
  neigung: null,
  bodenTyp: null,
  bodenKalkgehalt: null,
  bodenDurchlaessigkeit: null,
  bodenHumus: null,
  bodenNaehrstoffgehalt: null,
  bodenAbtrag: null,
  wasserhaushalt: null,
  beschreibung: null,
  katasterNr: null,
  status: null,
  statusUnklar: null,
  statusUnklarGrund: null,
  apberRelevant: null,
  bekanntSeit: null,
  eigentuemer: null,
  kontakt: null,
  nutzungszone: null,
  bewirtschafter: null,
  bewirtschaftung: null,
  ekfrequenz: null,
  ekfrequenzAbweichend: null,
  ekfrequenzStartjahr: null,
  ekfKontrolleur: null,
  bemerkungen: null,
}

export const simpleTypes = {
  nr: 'number',
  gemeinde: 'string',
  flurname: 'string',
  radius: 'number',
  hoehe: 'number',
  exposition: 'string',
  klima: 'string',
  neigung: 'string',
  bodenTyp: 'string',
  bodenKalkgehalt: 'string',
  bodenDurchlaessigkeit: 'string',
  bodenHumus: 'string',
  bodenNaehrstoffgehalt: 'string',
  bodenAbtrag: 'string',
  wasserhaushalt: 'string',
  beschreibung: 'string',
  katasterNr: 'string',
  status: 'number',
  statusUnklar: 'boolean',
  statusUnklarGrund: 'string',
  apberRelevant: 'boolean',
  apberRelevantGrund: 'number',
  bekanntSeit: 'number',
  eigentuemer: 'string',
  kontakt: 'string',
  nutzungszone: 'string',
  bewirtschafter: 'string',
  bewirtschaftung: 'string',
  ekfrequenz: 'uuid',
  ekfrequenzAbweichend: 'boolean',
  ekfrequenzStartjahr: 'number',
  ekfKontrolleur: 'uuid',
  bemerkungen: 'string',
}
