import { types } from 'mobx-state-tree'

export const type = types.model({
  typ: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  datum: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  jahr: types.optional(types.maybeNull(types.number), null),
  bearbeiter: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  vitalitaet: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  ueberlebensrate: types.optional(types.maybeNull(types.number), null),
  entwicklung: types.optional(types.maybeNull(types.number), null),
  ursachen: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  erfolgsbeurteilung: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  umsetzungAendern: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  kontrolleAendern: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  bemerkungen: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  lrDelarze: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  flaeche: types.optional(types.maybeNull(types.number), null),
  lrUmgebungDelarze: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  vegetationstyp: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  konkurrenz: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  moosschicht: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  krautschicht: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  strauchschicht: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  baumschicht: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  idealbiotopUebereinstimmung: types.optional(
    types.maybeNull(types.number),
    null,
  ),
  handlungsbedarf: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  flaecheUeberprueft: types.optional(types.maybeNull(types.number), null),
  planVorhanden: types.optional(types.maybeNull(types.boolean), null),
  deckungVegetation: types.optional(types.maybeNull(types.number), null),
  deckungNackterBoden: types.optional(types.maybeNull(types.number), null),
  deckungApArt: types.optional(types.maybeNull(types.number), null),
  jungpflanzenVorhanden: types.optional(types.maybeNull(types.boolean), null),
  vegetationshoeheMaximum: types.optional(types.maybeNull(types.number), null),
  vegetationshoeheMittel: types.optional(types.maybeNull(types.number), null),
  gefaehrdung: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  apberNichtRelevant: types.optional(types.maybeNull(types.boolean), null),
  apberNichtRelevantGrund: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
  ekfBemerkungen: types.optional(
    types.maybeNull(types.union(types.string, types.number)),
    null,
  ),
})

export const initial = {
  typ: null,
  datum: null,
  jahr: null,
  bearbeiter: null,
  vitalitaet: null,
  ueberlebensrate: null,
  entwicklung: null,
  ursachen: null,
  erfolgsbeurteilung: null,
  umsetzungAendern: null,
  kontrolleAendern: null,
  bemerkungen: null,
  lrDelarze: null,
  flaeche: null,
  lrUmgebungDelarze: null,
  vegetationstyp: null,
  konkurrenz: null,
  moosschicht: null,
  krautschicht: null,
  strauchschicht: null,
  baumschicht: null,
  idealbiotopUebereinstimmung: null,
  handlungsbedarf: null,
  flaecheUeberprueft: null,
  planVorhanden: null,
  deckungVegetation: null,
  deckungNackterBoden: null,
  deckungApArt: null,
  jungpflanzenVorhanden: null,
  vegetationshoeheMaximum: null,
  vegetationshoeheMittel: null,
  gefaehrdung: null,
  apberNichtRelevant: null,
  apberNichtRelevantGrund: null,
  ekfBemerkungen: null,
}

export const simpleTypes = {
  typ: 'string',
  datum: 'date',
  jahr: 'number',
  bearbeiter: 'uuid',
  vitalitaet: 'string',
  ueberlebensrate: 'number',
  entwicklung: 'number',
  ursachen: 'string',
  erfolgsbeurteilung: 'string',
  umsetzungAendern: 'string',
  kontrolleAendern: 'string',
  bemerkungen: 'string',
  lrDelarze: 'string',
  flaeche: 'number',
  lrUmgebungDelarze: 'string',
  vegetationstyp: 'string',
  konkurrenz: 'string',
  moosschicht: 'string',
  krautschicht: 'string',
  strauchschicht: 'string',
  baumschicht: 'string',
  idealbiotopUebereinstimmung: 'number',
  handlungsbedarf: 'string',
  flaecheUeberprueft: 'number',
  planVorhanden: 'boolean',
  deckungVegetation: 'number',
  deckungNackterBoden: 'number',
  deckungApArt: 'number',
  jungpflanzenVorhanden: 'boolean',
  vegetationshoeheMaximum: 'number',
  vegetationshoeheMittel: 'number',
  gefaehrdung: 'string',
  apberNichtRelevant: 'boolean',
  apberNichtRelevantGrund: 'string',
  ekfBemerkungen: 'string',
}
